import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import './Questions.scss';

const DAN = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">2% DAŇ</h1>
      <p>
        Dobrý deň,
        Poukazovanie 2% z dane je jednou z možností, ako môžete ovplyvniť prerozdelenie časti svojich daní v prospech verejne prospešných organizácií či projektov. Táto možnosť je dostupná pre občanov, ktorí platia dane z príjmu fyzických osôb na Slovensku.
        Ak potrebujete bližšie informácie o tom, ako poukázanie 2% z dane funguje a ako môžete túto možnosť využiť, kliknite prosím na nasledujúci odkaz tu: 
        <br />
        <a href="https://rozhodni.sk/poukazatel/tlaciva-na-poukazanie-2-z-dane/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#00f' }}>Bližšie informácie o tom, ako poukázanie 2% z dane funguje</a>

        

        <br />
        <a href="https://e.pcloud.link/publink/show?code=XZxY4dZ1qr8SgTwnP5V5H1lTpLJ8BBLB7f7" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#00f' }}>Predpripravené tlačivo na poukázanie 2% z dane ZUŠ Irkutská</a>
      </p>

      <br />
        Ak máte ďalšie otázky, neváhajte nás kontaktovať.


        <br />
    </div>
  );
};

export default MotionWrap(AppWrap(DAN, 'app__skills', 'DAN'), 'DAN', 'app__whitebg');
